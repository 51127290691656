import { makeAutoObservable } from 'mobx'
import {
  AcademicAccountStatus,
  AcademicRolesResponse,
  IAcademicData,
  IAcademicGroup,
  IAcademicRequiest,
  IAcademicRolesParams,
  IGroupAssistants,
  ISingleAcademicparams,
} from '@/api/academic'
import { academicApi } from '@/api/academic/academic'
import { StaffGroupDetail } from '@/api/groups'
import { StaffWorkStatus } from '@/api/users'
import { addCatchNotification } from '@/modules/notifications'
import { OrderBy } from '@/utils'
import { ISchedules } from '../groups/types'
import { IAcademicGroupInfo, KeyType, RoleTypes, TAcademicStatus } from './types'

class AcademicStore {
  page = 1
  perPage = 10
  keys: KeyType = ['firstName', 'lastName']
  value = ''
  orderBy: OrderBy = OrderBy.DESC
  isActive: null | boolean = null
  workStatus: AcademicAccountStatus | null = AcademicAccountStatus.Active
  roleId: number | null = null
  total = 0
  academics: IAcademicData[] = []
  roles: AcademicRolesResponse[] = []
  rolesKeys: KeyType = ['firstName', 'lastName']
  rolesValue = ''
  rolesOrderBy: OrderBy = OrderBy.DESC
  rolesPage = 1
  rolesPerPage = 10
  rolesType = RoleTypes.ACADEMIC
  singleAcademic: IAcademicData | null = null
  singleTeacherGroupModal = false
  singleAssistantGroupModal = false
  academicGroups: IAcademicGroup[] = []
  academicProfileModal = false
  academicGroupTeacher: IAcademicData | null = null
  academicGroupAssistant: IGroupAssistants[] | [] = []
  academicGroupId = 0
  academicGenerateProfileModal = false
  academicGroupInfo: IAcademicGroupInfo | null = null
  singleTeacherGroup: StaffGroupDetail[] | null = null
  officeIds: number[] | null = null
  groupTeachers: ISchedules | null = null
  academicPhotoModal = false
  academicPhotoUrl: string | null = null
  staffSystemStatus: number | null = StaffWorkStatus.Working
  categoryId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setAcademicPhotoUrl = (params: string | null) => {
    this.academicPhotoUrl = params
  }

  setAcademicPhotoModal = (params: boolean) => {
    this.academicPhotoModal = params
  }

  changeAcademicStatus = (data: TAcademicStatus) => academicApi.changeAcademicStatus(data)

  resetFilter = () => {
    this.roleId = null
    this.workStatus = null
    this.isActive = null
    this.page = 1
    this.officeIds = null
    this.staffSystemStatus = null
    this.categoryId = null
  }

  setSingleTeacherGroup = (params: StaffGroupDetail[] | null) => {
    this.singleTeacherGroup = params
  }

  setOfficeIds = (params: number[] | null) => {
    this.officeIds = params
  }

  setGroupTeachers = (params: ISchedules | null) => {
    this.groupTeachers = params
  }

  setCategoryId = (value: number | null) => {
    this.categoryId = value
  }

  getAcademics = (params: IAcademicRequiest) =>
    academicApi
      .getAcademic(params)
      .then((res) => {
        if (res.success) {
          this.setAcademic(res.data.academics)
          this.setTotal(res.data.total)
        }

        return res
      })
      .catch(addCatchNotification)

  getAcademicSingle = (params: number) =>
    academicApi
      .getSingleAcademic(params)
      .then((res) => {
        if (res.success) {
          this.setSingleAcademic(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getRoles = (params: IAcademicRolesParams) =>
    academicApi
      .getTeacherRoles(params)
      .then((res) => {
        if (res.success) {
          this.setRoles(res.data.roles)
        }

        return res
      })
      .catch(addCatchNotification)

  getAcademicGroups = (params: ISingleAcademicparams) =>
    academicApi
      .getAcademicGroups(params)
      .then((res) => {
        if (res.success) {
          this.setAcademicGroups(res.data.staffGroups)
        }

        return res
      })
      .catch(addCatchNotification)

  getAcademicGroupAcademics = (params: number) =>
    academicApi
      .getGroupAcademics(params)
      .then((res) => {
        if (res.success) {
          this.academicGroupInfo = res?.data
        }

        return res
      })
      .catch(addCatchNotification)

  setAcademicGroupTeacher = (params: IAcademicData) => {
    this.academicGroupTeacher = params
  }

  setAcademicGroupAssistant = (params: IGroupAssistants[]) => {
    this.academicGroupAssistant = params
  }

  setAcademicGroupId = (params: number) => {
    this.academicGroupId = params
  }

  setAcademicGroups = (params: IAcademicGroup[]) => {
    this.academicGroups = params
  }

  setAcademic = (params: IAcademicData[]) => {
    this.academics = params
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setValue = (value: string) => {
    this.value = value
  }

  setRoles = (roles: AcademicRolesResponse[]) => {
    this.roles = roles
  }

  setRoleId = (value: number) => {
    if (!value) {
      this.roleId = null

      return
    }

    this.roleId = value
  }

  setWorkStatus = (value: number) => {
    if (!value) {
      this.workStatus = null

      return
    }

    this.workStatus = value
  }

  setIsActive = (value: boolean) => {
    this.isActive = value
  }

  setSingleAcademic = (singleAcademic: IAcademicData | null) => {
    this.singleAcademic = singleAcademic
  }

  setSingleTeacherGroupModal = (params: boolean) => {
    this.singleTeacherGroupModal = params
  }

  setSingleAssistantGroupModal = (params: boolean) => {
    this.singleAssistantGroupModal = params
  }

  setAcademicAddProfileModal = (params: boolean) => {
    this.academicGenerateProfileModal = params
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setStaffSystemStatus = (value: number | null) => {
    this.staffSystemStatus = value
  }

  reset = () => {
    this.page = 1
    this.perPage = 10
    this.total = 0
    this.singleAcademic = null
    this.academics = []
    this.value = ''
    this.rolesValue = ''
    this.orderBy = OrderBy.DESC
    this.rolesOrderBy = OrderBy.DESC
    this.rolesType = RoleTypes.ACADEMIC
    this.workStatus = AcademicAccountStatus.Active
    this.academicGroupId = 0
    this.rolesPage = 1
    this.rolesPerPage = 10
    this.roleId = null
    this.isActive = null
    this.academicGroupInfo = null
    this.singleTeacherGroup = null
    this.academicGroupTeacher = null
    this.academicProfileModal = false
    this.singleTeacherGroupModal = false
    this.singleAssistantGroupModal = false
    this.academicGenerateProfileModal = false
    this.roles = []
    this.academicGroups = []
    this.academicGroupAssistant = []
    this.staffSystemStatus = null
    this.categoryId = null
  }
}

export const academicStore = new AcademicStore()
