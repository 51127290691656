import { MasterClass } from '@/api/study-department/types'
import { VideoStatus } from '@/stores/study-department/types'
import { Tag } from 'antd'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { tagColor, videoStatusText } from '../../constants'
import { useStores } from '@/stores'

type Props = {
  video: MasterClass
}

export const VideoStat = observer((props: Props) => {
  const { studyDepartment } = useStores()
  const status = props.video.currentStatus || props.video.status
  const statusText = useMemo(() => videoStatusText[status], [status])
  const statusColor = useMemo(() => tagColor[status], [status])

  const handleHistoryClick = () => {
    if (props.video.percentage) return

    studyDepartment.setVideoHistoryModal(true)
    studyDepartment.setSelectedMasterClass(props.video)
  }

  return (
    <Tag className="status" onClick={handleHistoryClick} color={statusColor}>
      {statusText}&nbsp;
      {(status === VideoStatus.Uploading || status === VideoStatus.Pause) && props.video.percentage !== undefined && (
        <b>{props.video.percentage}%</b>
      )}
    </Tag>
  )
})
