import { observer } from 'mobx-react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Popconfirm } from 'antd'
import { MasterClass } from '@/api/study-department/types'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { VideoStatus } from '@/stores/study-department/types'
import { PermissionOptions, permissionObjectTypes, useCheckPermission } from '@/modules/permission'

type Props = {
  masterClass: MasterClass
}

export const Actions = observer(({ masterClass }: Props) => {
  const { studyDepartment } = useStores()

  const [isAvailableDeleteVideo] = useCheckPermission({
    module: permissionObjectTypes.studySkillClasses,
    option: PermissionOptions.Delete,
  })

  const [isAvailableEditVideo] = useCheckPermission({
    module: permissionObjectTypes.studySkillClasses,
    option: PermissionOptions.Update,
  })

  const handleEdit = () => {
    studyDepartment.setEditMasterClassModal(true)
    studyDepartment.setSelectedMasterClass(masterClass)
  }

  const handleDelete = () => {
    studyDepartment.setMasterClassesLoading(true)

    studyDepartment
      .deleteMasterClass(masterClass.id)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successFullySaved)

          studyDepartment.getMasterClasses({
            ...studyDepartment.masterClassesFilter,
            videoType: studyDepartment.activeMasterClass,
          })
        }
      })
      .catch(addCatchNotification)
      .finally(() => studyDepartment.setMasterClassesLoading(false))
  }

  if (masterClass.status === VideoStatus.Uploading) return null

  return (
    <Flex gap={10}>
      {isAvailableEditVideo && <Button onClick={handleEdit} type="primary" icon={<EditOutlined />} />}

      {isAvailableDeleteVideo && (
        <Popconfirm placement="left" onConfirm={handleDelete} title={mainDictionary.areYouSureToDelete}>
          <Button type="primary" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      )}
    </Flex>
  )
})
