export const ENDPOINTS = {
  permissionObjectsAll: '/roles/permission-objects/all',
  permissionObjectsMine: '/roles/permission-objects/mine',
} as const

export const permissionObjectTypes = {
  staffControl: 'staff_control',
  staffControlRoles: 'staff_control:roles',
  staffControlUsers: 'staff_control:users',
  audits: 'staff_control:audit_logs',
  staffControlFaceIdLogs: 'staff_control:face_id_logs',
  staffControlBranchOffice: 'staff_control:offices',
  staffControlDevices: 'staff_control:devices',

  users: 'users',
  usersAcademic: 'users:academic',
  usersAssistants: 'users:assistants',
  usersStaff: 'users:staff',
  usersStudents: 'users:students',
  usersTeachers: 'users:teachers',

  allGroups: 'all_groups',
  allGroupsAllHomeworks: 'all_groups:all_homeworks',
  allGroupsAttendance: 'all_groups:attendance',
  allGroupsCheckHomeworks: 'all_groups:check_homeworks',
  allGroupsExam: 'all_groups:exam',
  allGroupsVideoUpload: 'all_groups:video_upload',
  allGroupsHomeworkStatistics: 'all_groups:homework_statistics',
  allGroupsJournal: 'all_groups:journal',

  myGroups: 'my_groups',
  myGroupsAllHomeworks: 'my_groups:all_homeworks',
  myGroupsAttendance: 'my_groups:attendance',
  myGroupsCheckHomeworks: 'my_groups:check_homeworks',
  myGroupsExam: 'my_groups:exam',
  myGroupsVideoUpload: 'my_groups:video_upload',
  myGroupsHomeworkStatistics: 'my_groups:homework_statistics',
  myGroupsJournal: 'my_groups:journal',

  gamification: 'gamification',
  gamificationActions: 'gamification:actions',
  gamificationGroups: 'gamification:groups',
  gamificationLevels: 'gamification:levels',
  gamificationMethodActions: 'gamification:method_actions',
  gamificationStatistics: 'gamification:statistics',
  gamificationUnits: 'gamification:units',
  gamificationBestStudents: 'gamification:best_students',

  profile: 'profile',
  study: 'study',
  studyCourses: 'study:courses',
  studyMonths: 'study:months',
  studyCurriculum: 'study:curriculum',
  studyHomeworkStatistics: 'study:homework_statistics',
  myGroupsExamChats: 'my_groups:exam_chats',
  allGroupsExamChats: 'all_groups:exam_chats',

  management: 'management',
  managementReports: 'management:reports',
  managementAutoCall: 'management:call',

  notifications: 'notifications',
  notificationsSent: 'notifications:sent',

  settings: 'settings',
  settingsStatus: 'student_statutes',
  settingStudentStatus: 'settings:student_statutes',
  settingsGamificationOffer: 'settings:gamification_oferta',

  store: 'store',
  storeCategories: 'store:categories',
  storeItems: 'store:items',
  storeTransactions: 'store:transactions',
  studentCertificate: 'student:certificate',
  settingsStudentStatuses: 'settings:student_statutes',
  studyHomeworkDb: 'study:homework_db',
  contracts: 'settings:contracts',
  events: 'events',
  eventsMasterClasses: 'events:master_classes',
  eventsCompetitions: 'events:competitions',
  usersStudentsSmsHistory: 'users:students_sms_history',
  studentDetail: 'users:student_detail',
  settingsMobileVersions: 'settings:mobile_versions',
  studySkillClasses: 'study:skill_classes',
} as const

export const permissionObjectTypesValues = Object.values(permissionObjectTypes)
