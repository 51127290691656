import { DetailedError, Upload } from 'tus-js-client'
import { calcUploadChunkSize } from '@/utils'

const uploadToKinescopeFileHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
}

type UploadToKinescopeFileParams = {
  file: File
  uploadUrl: string
  onError?: (error: DetailedError | Error) => void
  onProgress?: (bytesUploaded: number, bytesTotal: number, percentage: number) => void
  onSuccess?: (file: File) => void
  origName: string
  id: number
}

export const uploadToKinescopeFile = ({
  file,
  uploadUrl,
  onError,
  onSuccess,
  onProgress,
  origName,
  id,
}: UploadToKinescopeFileParams) => {
  let lastOffset: number | null = null

  const upload = new Upload(file, {
    addRequestId: true,
    chunkSize: calcUploadChunkSize(file.size) * 1024 * 1024,
    headers: uploadToKinescopeFileHeaders,
    removeFingerprintOnSuccess: true,

    retryDelays: [1000, 200000, 300000, 500000],
    uploadUrl,
    metadata: {
      filename: file.name,
      filetype: file.type,
      id: uploadUrl,
      uploadId: id.toString(),
      chapterId: toString(),
      origName,
    },

    onError: (error) => {
      onError?.(error)

      console.error(error)
    },

    onProgress: (bytesUploaded, bytesTotal) => {
      const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)

      onProgress?.(bytesUploaded, bytesTotal, parseInt(percentage, 10))
    },

    onSuccess: () => {
      onSuccess?.(file)
    },
  })

  upload.findPreviousUploads().then((previousUploads) => {
    if (previousUploads.length && previousUploads[0].metadata.id === uploadUrl) {
      upload.resumeFromPreviousUpload(previousUploads[0])
    }

    upload.start()
  })

  const handleAbort = () => {
    // @ts-ignore
    lastOffset = upload._offset

    return upload.abort()
  }
  const handleStart = () => {
    if (lastOffset) {
      // @ts-ignore
      upload._offset = lastOffset
      lastOffset = null
    }

    upload.start()
  }

  return {
    abort: handleAbort,
    start: handleStart,
  }
}
