import { MasterClass } from '@/api/study-department/types'
import { useStores } from '@/stores'
import { VideoStatus } from '@/stores/study-department/types'
import { observer } from 'mobx-react'
import styles from './video-name.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

type Props = {
  video: MasterClass
}

export const VideoName = observer(({ video }: Props) => {
  const { studyDepartment } = useStores()

  const handleNameClick = () => {
    studyDepartment.setSelectedMasterClass(video)
    studyDepartment.setVideoModal(true)
  }

  if (video.status === VideoStatus.Uploading) return <span>{video?.name}</span>

  return (
    <b className={cx('video-name')} onClick={handleNameClick}>
      {video?.name}
    </b>
  )
})
