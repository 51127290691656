import { RcFile } from 'antd/es/upload'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Upload, UploadOptions } from 'tus-js-client'
import { Endpoints, stage } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { TeacherVoiceUrl } from '@/api/teachers'
import { stores } from '@/stores'
import { calcUploadChunkSize } from '@/utils'
import { IResponse } from '../types'
import { UploadEndpoints } from './endpoints'
import { IBlogBannerResponse, IUpload, IUploadResponse } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class UploadsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  uploadBlogBanner = (params: any): Promise<IResponse<IBlogBannerResponse>> =>
    this.post(UploadEndpoints.Upload, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  uploadTeacherPhoto = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(`${UploadEndpoints.Upload}${UploadEndpoints.StaffAttendances}`, formData)

  upload = (formData: FormData): Promise<IResponse<IUpload[]>> => this.post(UploadEndpoints.Upload, formData)

  uploadStaffProfile = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(`${UploadEndpoints.Upload}${UploadEndpoints.StaffProfile}`, formData)

  uploadContract = (formData: FormData, onProgress?: (percentage: number) => void): Promise<IResponse<IUpload[]>> => {
    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

          if (onProgress) {
            onProgress(percentage)
          }
        }
      },
    }

    return this.post(`${UploadEndpoints.Upload}${Endpoints.ContractAttachment}`, formData, config)
  }

  uploadProductImage = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(`${UploadEndpoints.Upload}${UploadEndpoints.Store}${UploadEndpoints.Items}`, formData)

  uploadCourseImage = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(`${UploadEndpoints.Upload}${UploadEndpoints.CourseImageIllustration}`, formData)

  uploadHomework = (formData: FormData, onProgress: UploadOptions): Promise<IResponse<IUploadResponse>> =>
    this.post(UploadEndpoints.HomeWorkUpload, formData, {
      onUploadProgress: (e) => {
        const percent = Math.floor((e.loaded / e.total!) * 100)

        //@ts-ignore
        onProgress({ percent })
      },
    })

  uploadNotificationBanner = (params: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(UploadEndpoints.UploadNotificationBanner, params)

  uploadEventBanner = (params: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(Endpoints.uploadEventBanner, params)

  uploadStudentPhoto = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(UploadEndpoints.UploadStudentPhoto, formData)

  uploadVideo = (file: RcFile, options: UploadOptions): Upload => {
    const { authStore } = stores
    const accessToken = authStore.token?.accessToken
    const endpoint = stage.apiUrl + UploadEndpoints.TusFiles

    return new Upload(file, {
      endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: calcUploadChunkSize(file.size) * 1024 * 1024,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${authStore.token?.accessToken}` }),
      },
      ...options,
    })
  }

  uploadTeacherRecordedVoiceUrl = (fileOrigName: string): Promise<IResponse<TeacherVoiceUrl>> =>
    this.post(UploadEndpoints.HomeWorkUpload, { fileOrigName })

  uploadAcademicVoiceRecordFiles = (files: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(`${UploadEndpoints.Upload}${UploadEndpoints.VoiceAttachmentForHomework}`, files)
}

export const uploadsApi = new UploadsApi(config)
